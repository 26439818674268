.fileUploadInput {
    z-index: 1;
    box-shadow: 0 0 6px var(--defaultColor);
    border-radius: 6px;
    width: 92vw;
    margin: 0 auto 8px auto;
}

@media screen and (min-width: 600px) {
    .fileUploadInput {
        width: 510px;
    }
}

.fileUploadInput > div {
    height: 30px;
    padding: 0;
}

.fileUploadInput > div > input {
    margin: 0;
    z-index: 1;
    border-radius: 7px 0 0 7px;
    font-size: 15px;
    user-select: none;
    cursor: pointer;
    line-height: 30px;
}

.fileUploadInput input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    height: 0;
    width: 0;
}

.fileUploadInput button {
    float: right;
    border: none;
    user-select: none;
    background-color: inherit;
    font-size: 30px;
    line-height: 25px;
}
