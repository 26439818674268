.details {
    text-align: center;
}

.details > p {
    width: 80vw;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    border-bottom: 1px var(--defaultColor) dotted;
    padding-bottom: 10pt;
    padding-left: 10px;
}

.details h3 {
    font-size: 19px;
    margin: 0;
}

.details > .cardContainer {
    width: 270px;
    margin: 5px auto 15px auto;
}

.details > .cardContainer > .imageContainer {
    width: inherit;
    height: 270px;
    margin: auto;
}

.images3flex {
    display: flex;
    width: 100vw;
    justify-content: space-around;
}

@media screen and (min-width: 600px) {
    .details > p {
        width: 500px;
    }
}
