#orderComponent button {
    opacity: 60%;
    font-size: 14pt;
    font-weight: bold;
    position: relative;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
    background-color: white;
    top: 5px;
    border: none;
    box-shadow: 0 0 2px 1px rgba(30, 39, 47, 0.67);
}

#saveOrder {
    left: calc(80vw - 140px);
}

#saveProducts {
    left: 0;
}

#orderComponent h2 {
    font-size: 19px;
}

#oldOrders {
    margin-top: 50px;
    display: block;
    width: 90vw;
}

@media screen and (min-width: 600px) {
    #oldOrders {
        width: 500px;
    }

    #saveOrder {
        left: 310px;
    }
}
