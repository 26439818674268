/*Star in Details:*/

#svgStarContainer {
    padding: 0;
    border-radius: 5pt;
    display: block;
    border: none;
    width: 30px;
    position: relative;
    top: -33px;
    left: calc(50vw + 90px);
    background-color: transparent;
}

@media screen and (min-width: 600px) {
    #svgStarContainer {
        left: calc(80vw)
    }
}

#svgStarContainer svg {
    opacity: 0.7;
    filter: drop-shadow(1px 1px 1px grey) drop-shadow(3px 2px 2px grey);
}

/* In Header: */

.starButton {
    padding: 0;
    border-radius: 5px;
    filter: drop-shadow(1px 1px 1px grey) drop-shadow(2px 2px 2px grey);
    display: block;
    border: none;
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-bottom: 5px;
}
