.good {
    background-color: transparent;
}

.bad {
    background-color: rgba(247, 216, 216, 0.6);
}

.form label {
    font-family: arial, sans-serif;
    font-size: .75em;
    margin-left: 5vw;
}

.form > input[type="text"], .form > textarea {
    box-shadow: 0 0 6px var(--defaultColor);
    display: block;
    text-align: center;
    font-size: 15px;
    width: 90vw;
    margin: 0 auto 3px auto;
    border-radius: 5px;
    border: 0 var(--defaultColor) solid;
    padding: 5px;
    min-height: 20px;
}

.form textarea {
    height: 100px;
    min-height: 100px;
}

.form > button {
    position: relative;
    right: 10px;
    display: block;
    margin: 15px 15px 15px auto;
    width: 60px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    background-color: black;
    border-radius: 5px;
    padding: 1px 5px 1px 5px;
    border: none;
    box-shadow: 0 0 2px 2px #102d4c;
}

@media screen and (min-width: 600px) {
    .form label {
        margin-left: calc(50vw - 250px);
    }

    .form > input[type="text"], .form > textarea {
        width: 500px;
    }

    .form > button {
        right: calc(50vw - 270px);
    }
}
