.orderLine img {
    max-height: 30px;
    max-width: 30px;
}

.orderLine p:nth-child(2) {
    display: flex;
    justify-content: center;
}

.orderCard {
    min-height: 90px;
    font-size: 12px;
    display: block;
    justify-content: center;
    box-shadow: 0 0 6px var(--defaultColor);
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.orderCard > h3 {
    text-align: center;
    padding-left: 5pt;
    margin-bottom: 2px;
    font-size: 16px;
}

.orderCard > p {
    text-align: center;
    color: #9f9484;
    margin: 0;
}

.orderLine, .orderHead {
    display: grid;
    grid-template-columns: 6fr 2fr 2fr 2fr;
    width: 90vw;
    align-content: center;
}

.orderLine > p {
    margin: 0 0 4px 5px;
    line-height: 30px;
}

.orderHead {
    color: #9f9484;
    font-weight: bold;
    border-bottom: 1px #9f9484 solid;
    margin-bottom: 5px;
}

.orderHead > p {
    margin: 13px 0 0 5px;
    line-height: 12px;
}

@media screen and (min-width: 600px) {
    .orderLine, .orderHead {
        width: 500px;
    }
}
