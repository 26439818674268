body {
    font-family: Verdana, "Lucida Sans Unicode", sans-serif;

    margin: 0;
    --defaultColor: rgba(197, 197, 197, 0.98);
    --brown: rgba(136, 98, 38, 0.49);

    --toastify-color-light: var(--brown);
    --toastify-color-info: #81baf3;
    --toastify-color-success: #76b076;
    --toastify-color-warning: #ffe26f;
    --toastify-color-error: #b73226;
    --toastify-toast-width: 520px;
    --toastify-toast-min-height: 84px;
    --toastify-toast-max-height: 600px;
    --toastify-font-family: sans-serif;
    --toastify-text-color-light: black;
}

.Toastify__toast-container--top-center {
    text-align: center;
    top: 50px !important;
    font-size: 20px;
    filter: drop-shadow(1px 1px 1px white) drop-shadow(2px 2px 2px white);
}
