
.productCard {
    width: 125px;
    text-align: center;
    box-shadow: 0 0 2px 1px rgba(234, 242, 248, 0.53);
    font-size: 11px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;

}

.productCard .textField {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.textField h3 {
    font-family: Helvetica, sans-serif;
    margin: -5px 0 0 0;
    padding: 5px;
    text-align: left;
    color: black;
    font-size: 11px;
    max-width: 80px;
    font-weight: bold;
}

.productCard .price {
    width: 40px;
    color: #000000;
    margin: 0;
    height: 13px;
    background-color: #fffefe;
    z-index: 10;
    border-bottom-right-radius: 12px;
    padding: 1px;
    font-size: 11px;
}

.productCard button {
    opacity: 60%;
    color: white;
    font-weight: bold;
    position: relative;
    background-color: black;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
    top: 5px;
    left: 35px;
    border: none;
    box-shadow: 0 0 2px 2px #102d4c;
}
